import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/nightOwl"
import React from "react"
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live"
import { copyToClipboard } from "../utils/copy-to-clipboard"

const LineNo = ({ number }) => (
  <span className="inline-block w-8 select-none opacity-30">{number}</span>
)

const CopyCodeButton = ({ title, onClickHandler }) => (
  <button
    className="absolute right-2 border-1 p-2 opacity-50 hover:opacity-100"
    onClick={onClickHandler}
  >
    {title}
  </button>
)

const Code = ({ codeString, language, ...props }) => {
  if (props["react-live"]) {
    return (
      <LiveProvider code={codeString} noInline={true} theme={theme}>
        <LiveEditor />
        <LiveError />
        <LivePreview />
      </LiveProvider>
    )
  }

  const handleClick = () => {
    copyToClipboard(codeString)
  }

  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        console.log(tokens)
        return (
          <pre
            className={className}
            style={{
              ...style,
              padding: "0.5em",
              margin: "1em 0",
              borderRadius: "6px",
              position: "relative",
            }}
          >
            <CopyCodeButton onClickHandler={handleClick} title="Copy" />
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                <LineNo number={i + 1} />
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )
      }}
    </Highlight>
  )
}

export default Code
