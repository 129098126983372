import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import firebaseConfig from "./config"

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const firestore = firebase.firestore()
const auth = firebase.auth()

export { firebase, firestore, auth }
