// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-posts-general-2021-04-29-code-post-mdx": () => import("./../../../content/posts/general/2021-04-29-code-post.mdx" /* webpackChunkName: "component---content-posts-general-2021-04-29-code-post-mdx" */),
  "component---content-posts-general-allokationtest-mdx": () => import("./../../../content/posts/general/allokationtest.mdx" /* webpackChunkName: "component---content-posts-general-allokationtest-mdx" */),
  "component---content-posts-grundlagen-optionen-mdx": () => import("./../../../content/posts/grundlagen/optionen.mdx" /* webpackChunkName: "component---content-posts-grundlagen-optionen-mdx" */),
  "component---content-posts-news-2021-04-28-first-post-mdx": () => import("./../../../content/posts/news/2021-04-28-first-post.mdx" /* webpackChunkName: "component---content-posts-news-2021-04-28-first-post-mdx" */),
  "component---content-posts-news-2021-05-11-neue-news-mdx": () => import("./../../../content/posts/news/2021-05-11-neue-news.mdx" /* webpackChunkName: "component---content-posts-news-2021-05-11-neue-news-mdx" */),
  "component---content-posts-news-2021-05-30-second-post-mdx": () => import("./../../../content/posts/news/2021-05-30-second-post.mdx" /* webpackChunkName: "component---content-posts-news-2021-05-30-second-post-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../../../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

