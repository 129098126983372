const firebaseConfig = {
  apiKey: "AIzaSyAJrOSPzIT7Ln3OiA9EMRgcWWeKBZ-emDw",
  authDomain: "finanzreise.firebaseapp.com",
  projectId: "finanzreise",
  storageBucket: "finanzreise.appspot.com",
  messagingSenderId: "303318340630",
  appId: "1:303318340630:web:941471c31fbf6bde0f5137",
  measurementId: "G-N32B6QCGTL",
}

export default firebaseConfig
