import React, { createContext, useState, useEffect, useContext } from "react"
import { auth } from "../firebase/fb"

const defaultState = {
  isSignedIn: false,
  userProfile: null,
  authIsReady: false,
}

const AuthContext = createContext(defaultState)

export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [userProfile, setUserProfile] = useState(null)
  const [authIsReady, setAuthIsReady] = useState(false)

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
      console.log(user)
      if (!!user) {
        setIsSignedIn(true)
        setUserProfile({ username: auth.currentUser.uid })
        setAuthIsReady(true)
      } else {
        setIsSignedIn(false)
        setUserProfile(null)
      }
    })

    return () => unregisterAuthObserver()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isSignedIn: isSignedIn,
        userProfile: userProfile,
        authIsReady: authIsReady,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
